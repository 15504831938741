const CRS = {
    Nombre: "CRS",
    Logo: './logos/CRS.png'
}

const Caritas = {
    Nombre: "Caritas",
    Logo: './logos/Caritas.png'
}

const Datos = CRS;

const Paleta1 = {
    colorPrimario: "#292f32",
    colorSecundario: "",
    textoPrimario: "white"
}

const Paleta2 = {
    colorPrimario: "white",
    colorSecundario: "#f4f5f7",
    textoPrimario: "#a2a2a2",
    textoSecundario: "#353535",
    textoTerciario: "white",
    textoCuarto: "rgb(53, 53, 53)",
}

const Version = "2020.12.05.1053";

const Paleta = Paleta2;

export {
    Datos,
    Paleta,
    Version
}